import ApiService from './ApiService'

export async function apiSignUpRequest(data) {
    return ApiService.fetchData({
        url: '/auth/register',
        method: 'post',
        data,
    })
}
export async function apiSignInRequest(data) {
    return ApiService.fetchData({
        url: '/auth/login',
        method: 'post',
        data,
    })
}


export async function apiPutPassword(data) {
    return ApiService.fetchData({
        url: `auth/reset/password`,
        method: 'post',
        data,
    })
}


export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: `auth/forgot/password`,
        method: 'post',
        data,
    })
}


export async function apiVerifyUser(data) {
    return ApiService.fetchData({
        url: `/auth/verify/user/forgot/password`,
        method: 'post',
        data,
    })
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetTree } from '../../../services/TreeService'


export const getTreeData = createAsyncThunk(
    'tree/data/get',
    async (data) => {
        const response = await apiGetTree(data)
        return response.data
    }
)


const dataSlice = createSlice({
    name: 'tree/data',
    initialState: {
        loading: false,
        downLine: [],

    },
    reducers: {
        setDownLine: (state, action) => {
            state.downLine = action.payload
        },
    },
    extraReducers: {
        [getTreeData.fulfilled]: (state, action) => {
            state.downLine = action.payload?.data
            state.loading = false
        },
        [getTreeData.pending]: (state) => {
            state.loading = true
        },

    },
})


export const { setDownLine } =
    dataSlice.actions

export default dataSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { signUpProducts } from '../../../../services/UserService'


export const getSignUpProduct = createAsyncThunk(
    'register/data/product',
    async () => {
        const response = await signUpProducts()
        return response.data
    }
)

const dataSlice = createSlice({
    name: 'register/data',
    initialState: {
        loading: false,
        sponsorBy: {},
        product: [],
        userDetails: {},
        productAndPaymentDetails: {}
    },
    reducers: {
        setSponsor: (state, action) => {
            state.sponsorBy = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload
        },
        setProductAndPaymentDetails: (state, action) => {
            state.userDetails = action.payload
        }
    },
    extraReducers: {
        [getSignUpProduct.fulfilled]: (state, action) => {
            state.product = action.payload?.data
        }
    }
})

export const { setSponsor, setLoading, setUserDetails, setProductAndPaymentDetails } = dataSlice.actions

export default dataSlice.reducer

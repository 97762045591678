import ApiService from './ApiService'

export async function apiGetUsers(data) {
    return ApiService.fetchData({
        url: '/user/get',
        method: 'post',
        data
    })
}

export async function apiUpdateUser(userId, data) {
    return ApiService.fetchData({
        url: `/user/update/${userId}`,
        method: 'put',
        data
    })
}
export async function signUpProducts() {
    return ApiService.fetchData({
        url: `/product/all`,
        method: 'post',
    })
} 


export async function apiPutUsers(data) {
    return ApiService.fetchData({
        url: '/update',
        method: 'update',
        data
    })
}


export async function apiGetUserDetails(userId) {
    return ApiService.fetchData({
        url: `/user/details/${userId}`,
        method: 'post',
    })
}

export async function apiGetStatistic() {
    return ApiService.fetchData({
        url: '/user/statistic',
        method: 'post',
    })
}

export async function apiChartData() {
    return ApiService.fetchData({
        url: '/user/chart',
        method: 'post',
    })
}


export async function apiDonutData() {
    return ApiService.fetchData({
        url: '/payout/income',
        method: 'post',
    })
}
import ApiService from './ApiService'

export async function apiGetProduct(data) {
    return ApiService.fetchData({
        url: '/product/all',
        method: 'post',
        data,
    })
}
export async function apiGetProductList(data) {
    return ApiService.fetchData({
        url: '/product/all/list',
        method: 'post',
        data,
    })
}
export async function apiGetUpdateProduct(data) {
    return ApiService.fetchData({
        url: '/product/update',
        method: 'put',
        data,
    })
}
export async function apiBuyProduct(data) {
    return ApiService.fetchData({
        url: '/product/buy',
        method: 'post',
        data,
    })
}
const appConfig = {
    // apiPrefix: 'https://referral-system-yuaa.onrender.com/api',
    apiPrefix: 'https://gmartnx-server.onrender.com/api',
    // apiPrefix: 'https://api-gmartsys.5techg.com/api',
    // apiPrefix: 'http://localhost:5000/api',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    referralAuthenticatedEntryPath: '/sign-up',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig

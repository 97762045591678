import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetProduct, apiGetProductList } from '../../../services/ProdutService'


export const getProducts = createAsyncThunk(
    'product/data/get',
    async (data) => {
        const response = await apiGetProduct(data)
        return response.data
    }
)
export const getProductList = createAsyncThunk(
    'product/data/get/list',
    async (data) => {
        const response = await apiGetProductList(data)
        return response.data
    }
)

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
}


export const initialFilterData = {
    status: '',
}

const dataSlice = createSlice({
    name: 'product/data',
    initialState: {
        loading: false,
        list: [],
        tableData: initialTableData,
        filterData: initialFilterData,
    },
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setList: (state, action) => {
            state.list = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: {
        [getProductList.fulfilled]: (state, action) => {
            state.loading = false
            state.productList = action.payload?.data
            state.tableData.total = action.payload?.total
        },
        [getProductList.pending]: (state) => {
            state.loading = true
        },
    },
})

export const { setTableData, setList, setFilterData,setLoading } =
    dataSlice.actions

export default dataSlice.reducer

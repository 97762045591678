import React, { Suspense, useMemo } from 'react'
import { Loading } from '../components/shared'
import useAuth from '../utils/hooks/useAuth'
import { useSelector } from 'react-redux'

const Layout = () => {

    const { authenticated } = useAuth()
    const { authority } = useSelector(
        (state) => state.auth.user
    )
    const AppLayout = useMemo(() => {
        if (authenticated && authority.length > 0) {
            return React.lazy(() => import('./main'))
        }


        return React.lazy(() => import('./authLayout'))
    }, [authenticated, authority])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            {
                (authenticated && authority.length === 0) && <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
            <AppLayout />
        </Suspense>
    )
}

export default Layout